import React, { useState } from 'react'
import { APIGatewayEventWebsocketRequestContextV2 } from 'aws-lambda'
import './App.css'
import useWebSocket from 'react-use-websocket'
import { Task } from '@aws-sdk/client-ecs'

interface Cluster {
  name: string
  services: [{ name: string; tags: [{ key: string; value: string }] }]
}

interface Services {
  clusters: Cluster[]
  requestContext: APIGatewayEventWebsocketRequestContextV2 | null
  tasks: Task[]
}

const WS_URL = process.env.REACT_APP_WS_HOST ? `wss://${process.env.REACT_APP_WS_HOST}/ws` : `wss://health-ws.dev.best-security.us/ws`

function App() {
  const [services, setServices] = useState<Services>({ clusters: [], requestContext: null, tasks: [] })

  const { sendMessage, lastMessage, readyState } = useWebSocket(WS_URL, {
    onOpen: (message) => {
      console.log('WebSocket connection established.')
      sendMessage('{"action": "getService"}')
    },
    onMessage: (message) => {
      if (message.data != '') {
        const data: any = JSON.parse(message.data)
        console.log('Data', data)

        if (data.requestContext?.routeKey === 'getService') {
          //console.log('onMessage', data)
          setServices(data)
        }
        if (data['detail-type'] === 'ECS Task State Change') {
          console.log('ECS Task State Change')
          if (services.tasks.find((task) => task.taskArn === data.detail.taskArn)) {
            setServices((prevState) => ({
              ...prevState,
              tasks: [
                ...prevState?.tasks.map((task) => {
                  if (task.taskArn === data.detail?.taskArn) {
                    return data.detail as Task
                  }
                  return task
                }),
              ],
            }))
          } else {
            setServices((prevState) => ({
              ...prevState,
              tasks: [data.detail, ...prevState.tasks],
            }))
          }
        }
      }
    },
  })

  return (
    <div>
      <ul>
        {services?.clusters.map((cluster, idx) => (
          <table key={idx} style={{ border: '1px solid black' }}>
            <tbody>
              <tr>
                <th colSpan={2}>Cluster: {cluster.name}</th>
              </tr>

              {cluster.services.map((svc, idx) => (
                <tr key={idx}>
                  <th key={1}>
                    <a href={svc.tags.find((tag) => tag.key === 'gitTagUrl')?.value}>{svc.name}</a>:
                  </th>
                  <td key={2}>
                    {services?.tasks.map((task, idx) =>
                      task.group == svc.name ? (
                        <span key={idx}>
                          [Task: {task.lastStatus},{' '}
                          Containers: [{task.containers?.map((container, idx) => (
                            <span key={idx}>
                              {container.name}: {container.lastStatus},{' '}
                            </span>
                          ))}]
                          ]
                        </span>
                      ) : null
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </ul>
    </div>
  )
}

export default App
